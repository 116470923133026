<template>
<div class="page-reporte-ventas-totales">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Cortes de Caja" class="elevation-1 px-5 py-3">

          <v-card-text>
            <v-form ref="form" lazy-validation>
              <v-container grid-list-md>
                <v-row>
                  <v-col sm="4" md="4" lg="4">
                    <v-autocomplete v-model="model.id_sucursal" :items="sucursales" :hide-no-data="true"
                        :hide-selected="true" :loading="loading_sucursal"
                        item-text="nombre" item-value="_id" label="Sucursal"></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6" md="6" lg="6">
                    <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                      <template v-slot:activator="{ on }">
                        <v-text-field v-on="on" v-model="model.fecha_de" label="Fecha De" color="secondary" prepend-icon="event" @blur="model.fecha_de = parse_date(model.fecha_de)" :rules="[rules.required, valida_fecha]">
                        </v-text-field>
                      </template>

                      <v-date-picker v-model="model.fecha_de" color="secondary" scrollable locale="es-mx" @input="menu1 = false">
                        <v-spacer />

                        <v-btn color="secondary" x-small @click="menu1 = false">
                          Cancelar
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col sm="6" md="6" lg="6">
                    <v-menu offset-x ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                      <template v-slot:activator="{ on }">
                        <v-text-field v-on="on" v-model="model.fecha_a" label="Fecha A" color="secondary" prepend-icon="event" @blur="model.fecha_a = parse_date(model.fecha_a)" :rules="[rules.required, valida_fecha]">
                        </v-text-field>
                      </template>

                      <v-date-picker v-model="model.fecha_a" color="secondary" scrollable locale="es-mx" @input="menu2 = false">
                        <v-spacer />

                        <v-btn color="secondary" x-small @click="menu2 = false">
                          Cancelar
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                <v-col sm="4" md="4" lg="4">
                  <v-switch v-model="model.solo_totales" label="Mostrar solo Totales" true-value="1" false-value="0"></v-switch>
                </v-col>
              </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="info" @click.native="consultar()">
              <v-icon>search</v-icon> Consultar
            </v-btn>
            <v-btn color="success" @click.native="generar_reporte()">
              <v-icon>done</v-icon> Generar Reporte
            </v-btn>            
          </v-card-actions>
          <v-card-text>
            <v-container>
              <v-client-table ref="vuetable" :data="registros" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>
                
                <template slot="fecha" slot-scope="props">
                  {{parse_date_time(props.row.fecha)}}
                </template>
                
                <template slot="supervisor.nombre" slot-scope="props">
                    <div v-if="props.row.supervisor.nombre=='TOTALES'" style="font-weight:bold;text-align:center !important;">{{ props.row.supervisor.nombre }}</div>
                    <div v-else>{{ props.row.supervisor.nombre }}</div>                    
                </template>
                <template slot="efectivo_retirado" slot-scope="props">
                    <div v-if="props.row.supervisor.nombre=='TOTALES'" style="font-weight:bold;text-align:right !important;">${{ formatNumber(parseFloat(props.row.efectivo_retirado).toFixed(2))}}</div>
                    <div v-else style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.efectivo_retirado).toFixed(2))}}</div>
                    
                </template>
                <template slot="monto_voucher" slot-scope="props">
                    <div v-if="props.row.supervisor.nombre=='TOTALES'" style="font-weight:bold;text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_voucher).toFixed(2))}}</div>
                    <div v-else style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_voucher).toFixed(2))}}</div>
                </template>
                <template slot="monto_transferencia" slot-scope="props">
                    <div v-if="props.row.supervisor.nombre=='TOTALES'" style="font-weight:bold;text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_transferencia).toFixed(2))}}</div>
                    <div v-else style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_transferencia).toFixed(2))}}</div>
                </template>
                <template slot="monto_cheques" slot-scope="props">
                    <div v-if="props.row.supervisor.nombre=='TOTALES'" style="font-weight:bold;text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_cheques).toFixed(2))}}</div>
                    <div v-else-if="props.row.monto_cheques" style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_cheques).toFixed(2))}}</div>
                    <div v-else style="text-align:right !important;">$0.00</div>
                </template>
                <template slot="monto_total" slot-scope="props">
                    <div v-if="props.row.supervisor.nombre=='TOTALES'" style="font-weight:bold;text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_total).toFixed(2))}}</div>
                    <div v-else style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_total).toFixed(2))}}</div>
                </template>
                <template slot="faltante" slot-scope="props">
                    <div v-if="(props.row.supervisor.nombre=='TOTALES' && props.row.faltante)" style="font-weight:bold;text-align:right !important;">                      
                      ${{ formatNumber(parseFloat(props.row.faltante).toFixed(2))}}                      
                    </div>                    
                    <div v-else-if="props.row.faltante" style="text-align:right !important;">
                      ${{ formatNumber(parseFloat(props.row.faltante).toFixed(2))}}                      
                    </div>                    
                </template>
                <template slot="sobrante" slot-scope="props">
                    <div v-if="(props.row.supervisor.nombre=='TOTALES' && props.row.sobrante)" style="font-weight:bold;text-align:right !important;">
                        ${{ formatNumber(parseFloat(props.row.sobrante).toFixed(2))}}
                      </div>
                      <div v-else-if="props.row.sobrante" style="text-align:right !important;">
                        ${{ formatNumber(parseFloat(props.row.sobrante).toFixed(2))}}
                      </div>
                </template>
                <template slot="monto_total_final" slot-scope="props">
                    <div v-if="props.row.supervisor.nombre=='TOTALES'" style="font-weight:bold;text-align:right !important;">
                        ${{ formatNumber(parseFloat(props.row.monto_total_final).toFixed(2))}}
                    </div>
                    <div v-else style="text-align:right !important;">
                        ${{ formatNumber(parseFloat(props.row.monto_total_final).toFixed(2))}}
                    </div>
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-show="props.row.tipo == 'corte_general'"
                    v-model="props.row.id" direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row._id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" 
                      @click.native="getRegistro(props.row._id)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>                    
                  </v-speed-dial>
                </template>
              </v-client-table>
            </v-container>
          </v-card-text>


        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

</div>
</template>

<script>
export default {
  components: {},
  watch: {
    model_filters: {
      handler(val) {
        this.$nextTick(() => {
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
      },
      deep: true
    },



  },
  mounted: function() {
    if (!this.verificaPermiso('t.cortes.cortes')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
    var today = window.moment().format("YYYY-MM-DD");
    this.model.fecha_de = today;
    this.model.fecha_a = today;
    this.get_sucursales();
  },
  data() {
    return {
      registros:[],
      columns: [
        "sucursal.nombre","fecha","cajero","supervisor.nombre","caja","efectivo_retirado","monto_voucher","monto_transferencia","monto_cheques","faltante","sobrante","monto_total_final"
      ],
      options: {
        filterable: ["sucursal.nombre","fecha","cajero","supervisor.nombre","caja","efectivo_retirado","monto_voucher","monto_transferencia","monto_cheques","faltante","sobrante"],
        sortable: ["sucursal.nombre","fecha","cajero","supervisor.nombre","caja","efectivo_retirado","monto_voucher","monto_transferencia","monto_cheques","faltante","sobrante"],
        pagination: false,         
        headings: {
          "supervisor.nombre":"Supervisor",
          "sucursal.nombre":"Sucursal",
          "efectivo_retirado":"Efectivo Retirado",
          "monto_voucher":"Total en Vouchers",
          "monto_transferencia":"Transferencias",
          "monto_cheques":"Cheques",
          "monto_total_final": "Monto Total",            
        },
      },
      sucursales:[],
      loading_sucursal:false,
      menu1: false,
      menu2: false,
      fab: [],
      isLoading: false,
      articulos_encontrados: [],
      id_articulo: "",

      search: "",
      model_filters: "",

      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Cortes",
          disabled: true,
          href: ""
        },
        {
          text: "Cortes de Caja",
          disabled: true,
          href: ""
        }
      ],

      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: []
    }
  },
  methods: {
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    valida_time: function(time) {
      if (time != null && time.toString() != "") {
        if (time.toString().length != 5)
          return "Hora no valida1";
        if (!time.match(/^\d{2}:(?:[0-5]\d)$/))
          return "Hora no valida2";
        var time_arr = time.split(":");
        if (time_arr.length != 2) {
          return "Hora no valida3";
        } else {
          if (isNaN(time_arr[0]) || isNaN(time_arr[1])) {
            return "Hora no valida4";
          }
          if (parseInt(time_arr[0]) < 24 && parseInt(time_arr[1]) < 60) {
            return true;
          } else {
            return "Hora no valida5";
          }
        }
      }
      return true;

    },
    clean_model: function() {
      return {
        id_sucursal:"",
        fecha_de: '',
        fecha_a: '',
        hora_de: '',
        hora_a: '',
        canceladas: "0",
        solo_totales:"0",
      };
    },
    get_sucursales: function () {
        this.loading_sucursal = true;
        let data = {
            "selector": {
                "type":"sucursales",
                "estatus": {
                    "$eq": "Activo"
                }
            },
            "fields": [
                "nombre", "_id"
            ]
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                this.sucursales = [];
                if (response.data.docs.length > 0)
                    this.sucursales = response.data.docs;
                    let todas = {
                        _id: '0',
                        nombre: 'TODAS'
                    };
                    this.sucursales.unshift(todas);
            })

            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las sucursales.",
                    footer: ""
                });
            }).then(()=>{
                this.loading_sucursal = false;
            });
    },
    formatNumber: function (x) {
      if (x == undefined) return 0;
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    parse_date_time(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD HH:mm");
    },
    consultar: function(){
      window.dialogLoader.show('Espere un momento por favor..');
      var where = {
        "type": "corte_caja",
        "tipo": "corte_general",
        "fecha": {
            "$gte": this.model.fecha_de,
            "$lte": this.model.fecha_a+"T23:59:59.999"
        }
      };

      if(this.model.id_sucursal != "" && this.model.id_sucursal != "0"){
        where["sucursal._id"] = this.model.id_sucursal;
      }
      console.log("Where: ", where);
      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            selector: where,
            sort: [{"type":"desc"},{"tipo":"desc"},{"fecha":"desc"}],
            use_index: "_design/8cc053cf114b6360314f06a6097f8079fa817542"
          },
        }).then(response => {
          if (response.data.docs.length) {
            var total_efectivo = 0;
            var total_voucher = 0;
            var total_transferencia = 0;
            var total_cheques = 0;
            var total_faltante = 0;
            var total_sobrante = 0;
            var total_final = 0;
            this.registros = response.data.docs;
            for(var k in this.registros){
              this.registros[k]["monto_total_final"] = parseFloat(this.registros[k]["monto_total"]);
              if(this.registros[k]["faltante"]){
                this.registros[k]["monto_total_final"] -= parseFloat(this.registros[k]["faltante"]);
                total_faltante += parseFloat(this.registros[k]["faltante"]);
              }
              if(this.registros[k]["sobrante"]){
                this.registros[k]["monto_total_final"] += parseFloat(this.registros[k]["sobrante"]);
                total_sobrante += parseFloat(this.registros[k]["sobrante"]);
              }
              total_efectivo += parseFloat(this.registros[k]["efectivo_retirado"]);
              total_voucher += parseFloat(this.registros[k]["monto_voucher"]);
              total_transferencia += parseFloat(this.registros[k]["monto_transferencia"]); 
              if(this.registros[k]["monto_cheques"])
                total_cheques += parseFloat(this.registros[k]["monto_cheques"]);
              total_final += this.registros[k]["monto_total_final"];
            }

            var totales = {
              "sucursal":{"nombre":""},
              "fecha":"",
              "cajero":"",
              "supervisor":{"nombre":"TOTALES"},
              "caja":"",
              "efectivo_retirado":total_efectivo,
              "monto_voucher":total_voucher,
              "monto_transferencia":total_transferencia,
              "monto_cheques":total_cheques,
              "faltante":total_faltante,
              "sobrante":total_sobrante,
              "monto_total_final":total_final
            };
            if(this.model.solo_totales.toString() == "1"){
              this.registros = [];
            } 
            this.registros.push(totales);
          } else {
            this.registros = [];
          }
        })
        .catch(error => {
          console.log(error)
        }).then(()=>{
          window.dialogLoader.hide();
        })
    },
    generar_reporte: function() {
      if (this.$refs.form.validate()) {
        try {
          window.dialogLoader.show('Espere un momento por favor..');

          if(this.model.solo_totales == null)
            this.model.solo_totales = "0";

          //console.log("MODEL: ", this.model);

          window.axios
          .post(process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_cortes_caja/", this.model, {            
                      "Content-type": "application/json",
                      "Access-Control-Allow-Origin": "*",
                      "Allow": "POST",
                      "cache-control": "no-cache",
                      "responseType": "arraybuffer"
            })
            .then(response => {
              var bytes = response.data;
              let blob = new Blob([bytes], {
                  type: response.headers["content-type"]
                }),
                url = window.URL.createObjectURL(blob);

              var a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = "cortes_caja.xlsx";
              a.click();
              a.parentNode.removeChild(a);
            })
            .catch(error => {
              console.log(error);
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al generar el reporte. Intente nuevamente.",
                footer: ""
              });
            }).then(() => {
              window.dialogLoader.hide();
            });
        } catch (error) {
          console.log("ERRORS: ", error);
        }
      }
    },



  }
}
</script>
